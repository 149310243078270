import React from 'react';
import './Datenschutz.css';

export default class Datenschutz extends React.Component {

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div className="App-datenschutz">
                <h2>Datenschutzerklärung</h2>
                <h3>1. Erfassung Allgemeiner Information</h3>
                <p>
                    Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet Service Providers und Ähnliches.
                    <br /><br />
                    Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen. Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Anonyme Informationen dieser Art werden von uns statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
                </p>
                <h3>2. SSL-Verschlüsselung</h3>
                <p>
                    Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
                </p>
                <h3>3. Newsletter</h3>
                <p>
                    Bei der Anmeldung zum Bezug unseres Newsletters werden die von Ihnen angegebenen Daten ausschließlich für diesen Zweck verwendet. Abonnenten können auch über Umstände per E-Mail informiert werden, die für den Dienst oder die Registrierung relevant sind (Beispielsweise Änderungen des Newsletter Angebots oder technische Gegebenheiten).
                    <br /><br />
                    Alle Daten werden DSGVO-konform in der IT-Infrastruktur des Unternehmens und des Softwareherstellers gespeichert, die mit einem hohen Sicherheitsstandard betrieben wird, um vor Zugriffen Dritter und Missbrauch zu schützen.
                    <br /><br />
                    Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das „DoubleOpt-in“- Verfahren ein. Hierzu protokollieren wir die Bestellung des Newsletters, den Versand einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben. Die Daten werden ausschließlich für den Newsletter Versand verwendet und nicht an Dritte weitergegeben.
                    <br /><br />
                    Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletter Versand können Sie jederzeit widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit uns Ihren entsprechenden Wunsch über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
                </p>
                <h3>4. Kontaktformular</h3>
                <p>
                    Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die von Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
                </p>
                <h3>5. Löschung bzw. Sperrung der Daten</h3>
                <p>
                    Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
                </p>
                <h3>6. Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h3>
                <p>
                    Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen Daten. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die Kontaktdaten finden Sie ganz unten.
                    <br /><br />
                    Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch die Löschung der Daten verlangen, soweit keine gesetzliche Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht, sperren wir Ihre Daten auf Wunsch.
                    <br /><br />
                    Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
                </p>
                <h3>7. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden.</h3>
                <p>
                    Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DSGVO ist unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.
                </p>
                <h3>8. Änderung unserer Datenschutzbestimmungen</h3>
                <p>
                    Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                </p>
                <h3>9. Fragen zum Datenschutz</h3>
                <p>
                    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an uns:
                </p>
                <p className="center">
                    Hubert Ebner Verkehrslehrmittel Gesellschaft m.b.H.<br /><br />
                    Jägerweg 4<br /><br />
                    4600 Thalheim b. Wels<br /><br />
                    Tel: +43 7242 252050<br /><br />
                    eMail: office@he-group.at<br /><br />
                    Webseite: https://www.he-group.at
                </p>
                <h3>10. Cookies</h3>
                <p>
                    Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Webseitenserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem über Ihren Computer und Ihre Verbindung zum Internet.
                    <br /><br />
                    Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
                    <br /><br />
                    In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
                    <br /><br />
                    Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Sie können die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
                </p>
                <h3>11. Verwendung von Script-Bibliotheken (Google Web Fonts)</h3>
                <p>
                    Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website Script-Bibliotheken und Schriftbibliotheken wie z. B. Google Web Fonts (https://www.google.com/webfonts/). Google Web Fonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Web Fonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
                    <br /><br />
                    Der Aufruf von Script-Bibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.
                    <br /><br />
                    Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: https://www.google.com/policies/privacy/
                </p>
                <h3>12. Verwendung von Google Maps</h3>
                <p>
                    Diese Webseite verwendet Google Maps API, um geographische Informationen visuell darzustellen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.
                    <br /><br />
                    Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google Produkten finden Sie hier: https://www.google.com/policies/privacy/
                </p>
                <h3>13. Verwendung von Adobe Fonts</h3>
                <p>
                    Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website Adobe Fonts (https://www.adobe.com/at/privacy/policies/adobe-fonts.html). Adobe Web Fonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Adobe Fonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
                    <br /><br />
                    Die allgemeine Datenschutzerklärung von Adobe finden Sie hier: https://www.adobe.com/privacy/policy.html
                </p>                
                <h3>14. Bestehen einer automatisierten Entscheidungsfindung</h3>
                <p>
                    Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung.
                </p>
            </div>
        );
    }
}